import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tableCellClasses,
  IconButton,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import NotificationsIcon from "@mui/icons-material/Notifications";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CaptionTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: 18,
    maxWidth: 30,
    minWidth: 20,
    wordWrap: "break-word",
    border: 0,
    fontWeight: "bold",
  },
}));

const AdditionalTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    padding: 5,
    border: 0,
    maxWidth: 50,
    minWidth: 40,
    wordWrap: "break-word",
  },
}));

export default function AlertTables(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableContainer = styled(TableContainer)`
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  `;
  // const startIndex = page * rowsPerPage;
  // const endIndex = startIndex + rowsPerPage;
  // const rows = props.data?.slice(startIndex, endIndex);
  const rows = props.data.filter((d) => d.alert !== null);
  const columns = 2;
  return (
    <StyledTableContainer
      component={Paper}
      sx={{ height: "100%", overflowY: "auto" }}
    >
      <Table
        sx={{ minWidth: "550px" }}
        aria-label="temperature alert table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <CaptionTableCell
              colSpan={2}
              align="center"
              style={{ fontSize: "1.5em" }}
            >
              Temperature alerts
            </CaptionTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id || row.id === 0 ? row.id : row._id}>
              <AdditionalTableCell align="right">
                <IconButton aria-label="Alert icon" style={{ padding: 0 }}>
                  <NotificationsIcon
                    style={{ stroke: "#f00", fill: "none", strokeWidth: 2 }}
                  />
                </IconButton>
              </AdditionalTableCell>
              <TableCell
                align="center"
                // key={row._id}
                style={{ whiteSpace: "pre-line" }}
              >
                <Typography variant="h5" paddingTop="0.4em">
                  {"Alert: " +
                    row["sentinelId"] +
                    ". Time: " +
                    row["timeOfReport"]}
                </Typography>
                <Typography
                  variant="h5"
                  // paddingTop="1em"
                  fontWeight="bold"
                  color="#bbb"
                >
                  {row["temperatureValueC"] + "\u00B0C"}
                </Typography>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
