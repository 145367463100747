import { MapContainer, TileLayer, Marker, Popup, useMapEvent, useMap } from "react-leaflet";
import React from "react";
import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
// import icon from "../Images/icon.png";
import L from "leaflet";

import MarkerClusterGroup from "./MarkerClusterGroup";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default function Map({ coords, display_name, markers, zoom }) {
  const MAP_MAX_ZOOM=18;
  // const { latitude, longitude } = coords;


  // const customIcon = new L.Icon({//creating a custom icon to use in Marker
  //   iconUrl: icon,
  //   iconSize: [25, 35],
  //   iconAnchor: [5, 30]
  // });

  function MapView() {
    let map = useMap();
    console.log(coords)
   // console.log(markers)
    map.setView(coords, map.getZoom());
     //Sets geographical center and zoom for the view of the map
    return null;
  }
  function MultipleMarkers() {
    const map = useMapEvent({
      click() {
        map.locate();
      }
    });

    

    return markers.map((coordinata, index) => {
      return ( coordinata.latitude&&
        <Marker key={index} position={[coordinata.latitude,coordinata.longitude]}>
          <Popup>
            <div>{"Temperature :"+coordinata.temperatureValueC+"°"}</div>
            <div>{"Battery :"+coordinata.batteryPercent+"%" }</div>
          </Popup>
        </Marker>
      );
    });
  }

  return (
    <MapContainer
      classsName="markercluster-map"
      center={coords}
      zoom={zoom}
      maxZoom={MAP_MAX_ZOOM}
      scrollWheelZoom={true}
      style={{ height: '35vh', width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> 
        contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup>
        <MultipleMarkers />
      </MarkerClusterGroup>
      
      <MapView />
    </MapContainer>
  );
}
