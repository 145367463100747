import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tableCellClasses,
  // TextField,
  // IconButton,
  // Select,
  // MenuItem,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.primary.light,
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
    fontSize: 16,
    maxWidth: 100,
    minWidth: 50,
    // wordWrap: "break-word",
    whiteSpace: 'nowrap',
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    maxWidth: 100,
    minWidth: 50,
    // wordWrap: "break-word",
    whiteSpace: 'nowrap',
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CaptionTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#243746", //theme.palette.primary.gra,
    color: theme.palette.common.white,
    fontSize: 18,
    maxWidth: 30,
    minWidth: 20,
    wordWrap: "break-word",
    border: 0,
  },
}));

// const AdditionalTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#ccc",
//     color: theme.palette.common.white,
//     fontSize: 25,
//     padding: 0,
//     border: 0,
//     maxWidth: 10,
//     minWidth: 10,
//     wordWrap: "break-word",
//   },
//   [`&.${tableCellClasses.body}`]: {
//     padding: 0,
//     border: 0,
//     maxWidth: 10,
//     minWidth: 10,
//     wordWrap: "break-word",
//   },
// }));

export default function CustomizedTables({ data, tableName, columnNames }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const tableData =  data;
  const rows = tableData?.slice(startIndex, endIndex);
  const columns = columnNames;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 800 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <CaptionTableCell colSpan={12} align="center">
              {tableName}
            </CaptionTableCell>
          </TableRow>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="right" key={column.id}>
                {column.headerName}
              </StyledTableCell>
            ))}
            {/* <AdditionalTableCell align="right">&nbsp;</AdditionalTableCell>
            <AdditionalTableCell align="right">&nbsp;</AdditionalTableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) => (
              <StyledTableRow key={row._id}>
                {columns.map((column) => (
                  <TableCell align="right" key={column.id} sx={{fontSize: 16}}>
                    {row[column.name]}
                  </TableCell>
                ))}
                {/* <AdditionalTableCell align="right">
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="Edit"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </AdditionalTableCell>
                <AdditionalTableCell align="right">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="Delete"
                    style={{ padding: 0 }}
                  >
                    <DeleteIcon style={{ color: "red", padding: 0 }} />
                  </IconButton>
                </AdditionalTableCell> */}
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[2, 10, 50]}
        component="div"
        count={tableData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
