import React, { useState, createContext, useEffect, useRef } from "react";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const isRun = useRef(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticatedS13")
      ? JSON.parse(localStorage.getItem("isAuthenticatedS13")) === true
      : false
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessTokenS13")
      ? JSON.parse(localStorage.getItem("accessTokenS13"))
      : null
  );
  /*********First approach Keycloack initialization  with ReactQuery approach************************/
  // const initializeKeycloack = async () => {
  //   try {
  //     const res = await keycloak.init({
  //       onLoad: "login-required",
  //     });
  //     return { authenticated: res, token: keycloak?.token };
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  // const {} = useQuery(["keycloakToken"], initializeKeycloack, {
  //   onSuccess: (data) => {
  //     setIsAuthenticated(data?.authenticated);
  //     setAccessToken(data?.token);
  //   },
  // });
  /*********End of ReactQuery Keycloack initialization**************************************************************/

  /*********Second approach Keycloack initialization  with useEffect approach************************/
  // const initializeKeycloack = async () => {
  //   try {
  //     const res = await keycloak.init({
  //       onLoad: "login-required",
  //     });
  //     const userRoles =
  //       keycloak?.tokenParsed?.resource_access["foodrus_5_1"]?.roles || [];
  //     const hasAccess = userRoles.some((role) => ALLOWED_ROLES.includes(role));

  //     if (hasAccess) {
  //       setIsAuthenticated(res);
  //       setAccessToken(keycloak?.token);
  //     } else {
  //       setIsAuthenticated(false);
  //       setAccessToken(keycloak?.token);
  //     }
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };
  useEffect(() => {
    if (isRun.current) return;
    isRun.current = true;
    // initializeKeycloack();
  }, []);
  /*********End of useEffect Keycloack initialization****************************************************************/
  const login = () => {
    setIsAuthenticated(true);
  };
  const logout = () => {
    setIsAuthenticated(false);
    setAccessToken(null);
    // if (keycloak) {
    //   keycloak.logout();
    //   setIsAuthenticated(false);
    //   setAccessToken(null);
    // }
  };

  useEffect(() => {
    localStorage.setItem(
      "isAuthenticatedS13",
      JSON.stringify(isAuthenticated) || ""
    );
    localStorage.setItem("accessTokenS13", JSON.stringify(accessToken) || null);
  }, [isAuthenticated, accessToken]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        accessToken,
        setAccessToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
