
import dayjs from "dayjs";

export function formatDate(date) {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
}

export function formatDateOnly(date) {
  return dayjs(date).format("YYYY-MM-DD");
}

export function generatePastDateRange(input) {
  let from;
  let to;
  if (input === "1") {
    from = dayjs().startOf("day").subtract(1, "day");
    to = dayjs().subtract(1, "day").endOf("day");
  } else {
    from = dayjs()
      .subtract(input - 1, "day")
      .startOf("day");
    to = dayjs().endOf("day");
  }

  return { from, to };
}

export function generateNext7DaysDateRange() {
  
  const from = new Date(dayjs().subtract(7, "day").startOf("day"));
  const to = new Date(dayjs().add(17, "day").endOf("day"));

  return { from, to };
}
