import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import Header from "../components/Header";
import Map from "../components/map/Map";
import AlertMessage from "../components/AlertMessage";
import Spinner from "../components/Spinner";
import CustomizedTables from "../components/tables/CusomizedTableswithoutSeachandSelect";

import { getDevices, getDevicesWithLocation } from "../api/device";
import { useErrorHandler } from "../hooks/useErrorHandler";
import {
  formatDate,
  generatePastDateRange,
} from "../utils/dateUtils";

const columns = [
  { id: 1, headerName: "Device Name", name: "sentinelId" },
  { id: 2, headerName: "Timestamp", name: "timeOfReport" },
  { id: 3, headerName: "Temperature", name: "temperatureValueC" },
  { id: 4, headerName: "Humidity", name: "humidity" },
  { id: 5, headerName: "IsMoving", name: "isMoving" },
];

function DeviceLocationPage() {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [devices, setDevices] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [device_select, setDeviceSelect] = useState("");
  const [keys, setKeys] = useState([]);
  const [position, setPosition] = useState();
  const { errorMessage, handleError } = useErrorHandler();
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [usequery, setUseQuery] = useState(false);

  
  const mapComponent = () => {
    console.log(position);
    if (position && position.length > 0)
      return (
        <Box
          // display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "column" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0">
          <Header title="DEVICE LOCATION" />
          {errorMessage && <AlertMessage message={errorMessage} />}
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "column" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "start" : "center"}>
            <FormControl variant="standard" sx={{ minWidth: 150 }}>
              {/* <InputLabel id="input-lable-select-key">Devices Locations</InputLabel> */}
              <Select
                // defaultValue ="All Devices Locations"
                labelId="select-range-label"
                id="select-range"
                value={device_select}
                label="Range"
                onChange={handleChangeRange}>
                {keys.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "column" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            //alignItems={smScreen ? "center" : "start"}
          >
            <Header title="Cold chain monitoring devices" />
            <Map
              coords={position}
              display_name={"sono il marker"}
              markers={markers}
              zoom={4}
            />
          </Box>
        </Box>
      );
  };
  // fetch position and device from the server
  const { error, isLoading } = useQuery(["getDevicesWithLocation", startDate], () => getDevicesWithLocation(new Date(startDate)), {
    enabled: usequery,
    onSuccess: (data) => {
      //setDevices(data);
      
      let tempPosition = [];
      console.log(data[0].latitude)
      if (data && data.length > 0 && data[0].latitude && data[0].longitude) { 
        tempPosition.push(data[0].latitude);
        tempPosition.push(data[0].longitude);
        setPosition(tempPosition);
        
      }
      if (position&&position.length === 0) { 
        tempPosition.push(40.4637);
        tempPosition.push(3.7492);
        setPosition(tempPosition);
      }
     setMarkers(data);
      let temp = [];
      temp.push("All Devices Locations");
      data.forEach((element) => {
        if (!temp.includes(element["sentinelId"]) && element.latitude && element.longitude) {
          temp.push(element["sentinelId"]);
        }
      });
      setKeys(temp);
      setDeviceSelect("All Devices Locations");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const generateDateForDevices = async () => {
    const { from, to } = await generatePastDateRange(30); //generateNext7DaysDateRange();
    setEndDate(to);
    setStartDate(from);
    setUseQuery(true)
  };

  const { errorTable, isLoadingTable } = useQuery(["getDevices", startDate], ()=>getDevices(new Date(startDate)), {
    enabled: usequery,
    onSuccess: (data) => {
      setDevices(data);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  useEffect(() => {
    generateDateForDevices();
  }, []);

  const handleChangeRange = (event) => {
    setDeviceSelect(event.target.value);
    const filterDevices = devices.filter(
      (device) => device.sentinelId === event.target.value
    );

    if (filterDevices.length > 0) {
      console.log(filterDevices)
      
      let tempPosition = [];
      if (filterDevices[0].latitude && filterDevices[0].longitude) {
        tempPosition.push(filterDevices[0].latitude);
        tempPosition.push(filterDevices[0].longitude);
        setPosition(tempPosition);
      }
      if (position&&position.length === 0) { 
        tempPosition.push(40.4637);
        tempPosition.push(3.7492);
        setPosition(tempPosition);
      }
      setMarkers(filterDevices);
    } else {
      setMarkers(devices);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  // if (error) {
  //   return <AlertMessage message="Failed to fetch data. Please try again." />;
  // }
  return (
    <Box mt="20px" ml="50px" mr="50px">
      {mapComponent()}
      {/* "UNCOMMENT THIS SECTION AND YOUR ARRAY DATA IN  data FIELD" */}
      <CustomizedTables
        data={devices}
        tableName="Devices"
        columnNames={columns}
      />
    </Box>
  );
}

export default DeviceLocationPage;
