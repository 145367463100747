import React, { useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { styled } from "styled-components";

const CustomLegend = (props) => {
  return (
    <div style={{ textAlign: "center", maxHeight: "50px", overflowY: "auto" }}>
      <ul
        style={{ listStyleType: "square", padding: 0, display: "inline-block" }}
      >
        {props.lines.map((line, index) => (
          <li
            key={index}
            style={{ display: "inline-block", marginRight: "20px" }}
          >
            <span style={{ color: line.color, fontSize: "1.2em" }}>
              {line.columnName}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`line-${index}`} className="line-info">
            <span className="line-name">{entry.name}: </span>
            <span className="line-value">{entry.value}</span>
          </p>
        ))}
      </div>
    );
  }
};

export default function LineChartOne({
  chartData,
  caption,
  timestampKey,
  type = "monotone",
  lines,
  transformData,
}) {
  const transformedData = transformData ? transformData(chartData) : chartData;
  //  console.log(transformedData);
  //  console.log(lines)
  const chartRef = useRef(null);
  return (
    <ChartDiv class_name="chart_div" ref={chartRef} style={{ height: "100%" }}>
      <div class_name="title" style={{ textAlign: "center" }}>
        <h2 className="p-2 mb-0">{caption}</h2>
      </div>
      <div style={{ height: "75%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={transformedData}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={timestampKey} padding={{ left: 0, right: 30 }} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {lines.map((line, index) => (
              <Line
                key={index}
                type={type}
                dataKey={line.name}
                stroke={line.color}
                name={line.name}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{ height: "25%" }}>
        <CustomLegend lines={lines} />
      </div>
    </ChartDiv>
  );
}

const ChartDiv = styled.div`
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 0.5rem;
  align-self: center;

  .title {
    margin-bottom: 1rem;
  }

  h5 {
    font-weight: 700;
  }

  .chart_grid {
    stroke: rgb(228, 225, 225);
  }

  @media screen and (max-width: 500px) {
    .recharts-wrapper {
      max-width: 450px;
      height: 133.333px;
    }
  }
`;
