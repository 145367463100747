import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";

import Header from "../components/Header";
import { CardDetail } from "../components/cards/CardDetail";
import AlertTables from "../components/tables/AlertTables";
import Spinner from "../components/Spinner";
import AlertMessage from "../components/AlertMessage";
import Dropdown from "../components/filter/Dropdown";
import DateSelection from "../components/filter/DateSelection";
import LineChartOne from "../components/charts/LineChartOne";
import BiLineChart from "../components/charts/BiLineChart";
import { useErrorHandler } from "../hooks/useErrorHandler";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  //getDevices,
  getDeviceList,
  getDevicesByName,
  getDevicesByNameAndDate,
  getQualityByDevicesByNameAndDate
} from "../api/device";

const linesDevice = [
  { name: "temperatureValueC", columnName: "Temperature", color: "#8884d8" },
  // { name: "humidity", columnName: "Humidity", color: "#82ca9d" },
];
const linesDeviceHumidity = [
  //{ name: "temperatureValueC", columnName: "Temperature", color: "#8884d8" },
  { name: "humidity", columnName: "Humidity", color: "#82ca9d" },
];
const linesAlert = [
  { name: "temperatureValueC", columnName: "Temperature", color: "#82ca9d" },
  { name: "alert", columnName: "Alert", color: "#c10000" },
];

const bilines = [
  {name: "Odor", columnName: "Odor", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")},
  {name: "Flavor", columnName: "Flavor", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")},
  {name: "Texture", columnName: "Texture", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")},
  {name: "Evolutionary", columnName: "Evolutionary", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")},
  {name: "Oxidation", columnName: "Oxidation", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")},
  {name: "Appearance", columnName: "Appearance", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")}
];

const Monitoring = () => {
  // const [tempAlert, setTempAlertData] = useState([]);
  // const [devices, setDevices] = useState([]);
  const [usequery, setUseQuery] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deviceSelect, setDeviceSelect] = useState([]);
  const [value, setValue] = useState("1");
  // const [errorNameDate, setErrorNameDate] = useState("");
  // const [errorName, setErrorName] = useState("");
  const { errorMessage, handleError } = useErrorHandler();
  const [chartDataQuality, setChartDataQuality] = useState([]);
 // const [bilines, setBiLines] = useState([]);

  // const { error: errorGetDevices, isLoading: isLoadingGetDevices } = useQuery(
  //   ["getDevices"],
  //   getDevices(startDate),
  //   {
  //     enabled:usequery,
  //     onSuccess: (data) => {
  //       let tempRow = data.filter((item) => item.temperatureValueC < 1 || item.temperatureValueC >4);
  //      // setTempAlertData(tempRow);
  //     },
  //     onError: (error) => {
  //       handleError(error);
  //     },
  //   }
  // );

  useEffect(() => {
    const alertData = deviceData.map((obj, index) => {
      const { temperatureValueC, timeOfReport, sentinelId } = obj;
      const alert =
        temperatureValueC < 1 || temperatureValueC > 5
          ? temperatureValueC
          : null;
      return { sentinelId, temperatureValueC, timeOfReport, alert, id: index };
    });
    
    setAlertData(alertData);
    //setChartDataQuality(qualityData);
  }, [deviceData]);


  const transformDataQuality= (data) => {
    const transformedData = {};
    data.forEach((item) => {
      const { sentinelId, date, Appearance, Evolutionary, Flavor, Odor, Oxidation, Texture, temperature } = item;

      if (!transformedData[date]) {
        transformedData[date] = {};
      }

      transformedData[date]["Appearance"] = Appearance; 
      transformedData[date]["Evolutionary"] = Evolutionary;
      transformedData[date]["Flavor"] = Flavor; 
      transformedData[date]["Odor"] = Odor;
      //transformedData[date]["Flavor"] = Flavor; 
      transformedData[date]["Oxidation"] = Oxidation;
      transformedData[date]["Texture"] = Texture; 
      transformedData[date]["temperature"] = temperature;

    });

    return Object.entries(transformedData).map(
      ([date, Appearance, Evolutionary, Flavor, Odor, Oxidation, Texture,temperature]) => ({
        date,
        ...Appearance,
        ...Evolutionary,
        ...Flavor,
        ...Odor,
        ...Oxidation,
        ...Texture,
        ...temperature
      })
    );
  };
  // TODO: Change to useQuery with enabled
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (startDate && endDate && selectedDevice) {
  //       try {
  //         const items = await getDevicesByNameAndDate(
  //           selectedDevice,
  //           startDate,
  //           endDate
  //         );
  //         setDeviceData(items);
  //       } catch (error) {
  //         setErrorNameDate(error);
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };
  //   fetchData();
  //   return () => {};
  // }, [startDate, endDate, selectedDevice, setDeviceData]);

  const {
    error: errorDevicesByNameAndDate,
    isLoading: isLoadingDevicesByNameAndDate,
  } = useQuery(
    ["getDevicesByNameAndDate", selectedDevice, startDate, endDate],
    () => getDevicesByNameAndDate(selectedDevice, startDate, endDate),
    {
      onSuccess: (data) => {
        setDeviceData(data);
      },
      onError: (error) => {
        //setErrorNameDate(error);
        handleError(error);
      },
      enabled: usequery,
    }
  );

  const {
    error: errorQualityByDevicesByNameAndDate,
    isLoading: isLoadingQualityByDevicesByNameAndDate,
  } = useQuery(
    ["getQualityByDevicesByNameAndDate", selectedDevice, startDate, endDate],
    () => getQualityByDevicesByNameAndDate(selectedDevice, startDate, endDate),
    {
      onSuccess: (data) => {
        console.log(data)
        setChartDataQuality(data);
       
      },
      onError: (error) => {
        //setErrorNameDate(error);
        handleError(error);
      },
      enabled: usequery,
    }
  );

  const { error: errorDevices, isLoadingGetDeviceList: isLoadingDevices } =
    useQuery(["deviceList"], getDeviceList, {
      onSuccess: (data) => {
        const datas = data.sort();
        let ret = data.map((d) => {
          return { sentinelId: d };
        });

        const sortedDevices = ret.sort((a, b) => {
          if (a.sentinelId < b.sentinelId) return -1;
          if (a.sentinelId > b.sentinelId) return 1;
          return 0;
        });

        //setDevices(datas);
        setSelectedDevice(datas[0]);
        inititateStartingData(datas[0]);
        setDeviceSelect(sortedDevices);
      },
      onError: (error) => {
        handleError(error);
      },
    });

  const inititateStartingData = async (sentinelId) => {
    try {
      const items = await getDevicesByName(sentinelId);
      setDeviceData(items);

      setStartDate(dayjs().subtract(7, "day"));
      console.log(startDate);
      setEndDate(dayjs().add(7, "day"));
      setUseQuery(true);
    } catch (error) {
      // setErrorName(error);
      handleError(error);
    }
  };

  const handleChangeDevice = (event) => {
    setSelectedDevice(event);
  };
  // if (isLoadingGetDevices) {
  //   return <Spinner />;
  // }

  // if (error || errorDevices || errorName || errorNameDate || errorRange) {
  //   return <AlertMessage message="Failed to fetch data. Please try again." />;
  // }
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    // setDeviceData(chartData)
  };
  return (
    <Box m="20px">
      <Header title="MONITORING" />
      {errorMessage && <AlertMessage message={errorMessage} />}
      {/* <DataSelectionView /> */}
      {/* Device- Date picker */}
      <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* Start of cards */}
              <Grid container spacing={2} justifyContent="center">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardDetail
                    data={{
                      value:
                        deviceData && deviceData.length !== 0
                          ? deviceData[deviceData.length - 1].temperatureValueC
                          : 0,
                      symbol: `\u00B0C`,
                      sx: { height: "150px", width: "150px", margin: "1em" },
                      title: "Last read value temperature",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardDetail
                    data={{
                      value:
                        deviceData && deviceData.length !== 0
                          ? deviceData[deviceData.length - 1].humidity
                          : 0,
                      symbol: "%",
                      sx: { height: "150px", width: "150px", margin: "1em" },
                      title: "Last read value humidity",
                    }}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardDetail
                    data={{
                      value: Math.floor(Math.random() * 5),
                      //symbol: "Ln",
                      sx: { height: "150px", width: "150px", margin: "1em" },
                      title: "Quality",
                    }}
                  />
                </Grid> */}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardDetail
                    data={{
                      value:
                        deviceData && deviceData.length !== 0
                          ? deviceData[deviceData.length - 1].batteryPercent
                          : 0,
                      symbol: "%",
                      sx: { height: "150px", width: "150px", margin: "1em" },
                      title: "Last read value battery",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardDetail
                    data={{
                      value:
                        deviceData && deviceData.length !== 0
                          ? deviceData[deviceData.length - 1].light
                          : 0,
                      symbol: "Ln",
                      sx: { height: "150px", width: "150px", margin: "1em" },
                      title: "Last read value Luminous",
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={12} lg={6} sx={{ height: 500 }}>
              <AlertTables data={alertData} />
            </Grid> */}
            </Grid>
          </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="left"
        justifyContent="left"
        style={{ marginBottom: "20px" }}
      >
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Dropdown
            selectedCategory={selectedDevice}
            onSelect={handleChangeDevice}
            data={deviceSelect}
            title="Device"
          />
        </Grid>
        <Grid item>
          <DateSelection
            onChangeDate={(newDate) => setStartDate(newDate)}
            dateSelected={startDate}
            lastNextDay={"Last"}
            label="From"
          />
        </Grid>
        <Grid item>
          <DateSelection
            onChangeDate={(newDate) => setEndDate(newDate)}
            dateSelected={endDate || ""}
            lastNextDay={"Last"}
            label="To"
          />
        </Grid>
      </Grid>

      {/* End of selection */}
      {isLoadingDevicesByNameAndDate ? (
        <Spinner />
      ) : (
        <div>
          <Grid sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Temperature" value="1" />
                  <Tab label="Humidity" value="2" />
                  <Tab label="Quality Predict" value="3" />
                </TabList>
              </Box>
              <TabPanel sx={{ padding: "0!important" }} value="1">
                <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                  <Grid item lg={6} md={12} xs={12}>
                    {deviceData && (
                      <LineChartOne
                        chartData={deviceData}
                        caption="Temperature"
                        timestampKey="timeOfReport"
                        lines={linesDevice}
                      ></LineChartOne>
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} xs={12}>
                    {alertData && (
                      <LineChartOne
                        chartData={alertData}
                        caption="Temperature and Alert"
                        timestampKey="timeOfReport"
                        type="linear"
                        lines={linesAlert}
                      ></LineChartOne>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel sx={{ padding: "0!important" }} value="2">
                <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                  <Grid item lg={6} md={12} xs={12}>
                    {deviceData && (
                      <LineChartOne
                        chartData={deviceData}
                        caption="Humidity"
                        timestampKey="timeOfReport"
                        lines={linesDeviceHumidity}
                      ></LineChartOne>
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} xs={12}>
                    {alertData && (
                      <LineChartOne
                        chartData={alertData}
                        caption="Temperature and Alert"
                        timestampKey="timeOfReport"
                        type="linear"
                        lines={linesAlert}
                      ></LineChartOne>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel sx={{ padding: "0!important" }} value="3">
                <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                  <Grid item lg={6} md={12} xs={12}>
                    {chartDataQuality && (
                       <BiLineChart
                       chartData={chartDataQuality}
                       caption="Quality"
                       timestampKey="date"
                       lines={bilines}
                       //transformData={transformDataQuality}
                     />
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} xs={12}>
                    {alertData && (
                      <LineChartOne
                        chartData={alertData}
                        caption="Temperature and Alert"
                        timestampKey="timeOfReport"
                        type="linear"
                        lines={linesAlert}
                      ></LineChartOne>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </div>
      )}
    </Box>
  );
};

export default Monitoring;
