import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DownloadIcon from "@mui/icons-material/Download";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DevicesIcon from "@mui/icons-material/Devices";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import InventoryIcon from "@mui/icons-material/Inventory";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CardOne } from "../components/cards/CardOne";
import LineChartOne from "../components/charts/LineChartOne";
import BiLineChart from "../components/charts/BiLineChart";
import Spinner from "../components/Spinner";
import AlertMessage from "../components/AlertMessage";
import Header from "../components/Header";
import AlertTables from "../components/tables/AlertTables";
import CustomizedTables from "../components/tables/CusomizedTables";

import { useErrorHandler } from "../hooks/useErrorHandler";

import { exportAsCSV } from "../utils/ExportUtils";

import {
  getDevices,
  getDeviceList,
  getDevicesByDate,
  getDevicesByDateAndMaxTemperature,
  getDevicesByNameAndDateAndMaxTemperature,
  getDevicesByNameAndDate,
  getDevicesByWatchListLoggerAndDateAndMaxTemperature,
  getDevicesByWatchListLoggerAndDate,
  getLocationNumber,
  getDeviceListTestCode,
  getQualityByWatchListLoggerAndDate
} from "../api/device";
import { getProducts } from "../api/products";
import {
  formatDate,
  generatePastDateRange,
  generateNext7DaysDateRange,
} from "../utils/dateUtils";

const columns = [
  { id: 1, headerName: "Device Name", name: "sentinelId" },
  { id: 2, headerName: "Timestamp", name: "timeOfReport" },
  { id: 3, headerName: "Temperature", name: "temperatureValueC" },
  { id: 4, headerName: "Humidity", name: "humidity" },
  { id: 5, headerName: "isMoving", name: "isMoving" },
];

function Dashboard() {
  // selected key
  const { errorMessage, handleError } = useErrorHandler();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [usequery, setUseQuery] = useState(false);
  const [numberOfDevices, setNumberOfDevices] = useState();
  const [averageTemperature, setAverageTemperature] = useState(0);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [numberOfAlerts, setNumberOfAlerts] = useState(0);
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [maxTemperature, setMaxTemperature] = useState("");
  const [lines, setLines] = useState([]);
  const [linesU, setLinesU] = useState([]);
  const [bilines, setBiLines] = useState([]);
  const [combineError, setCombineError] = useState("");
  const [quality, setQuality] = useState(0);
  const [value, setValue] = useState("1");

  // all the data
  const [chartData, setChartData] = useState([]);
  const [chartDataQuality, setChartDataQuality] = useState([]);
  const [tempAlert, setTempAlertData] = useState([]);
  const [devices, setDevices] = useState([]);

  const fetchData = useCallback(
    async (mounted) => {
      console.log("fetch data");
      //if (startDate === "") return;

      let items = [];
      let quality = [];

      try {
        if (selectedDevice === "All devices") {
          if (maxTemperature) {
            items = await getDevicesByDateAndMaxTemperature(
              startDate,
              endDate,
              maxTemperature
            );
          } else {
            items = await getDevicesByDate(
              formatDate(startDate),
              formatDate(endDate)
            );
          }
        } else {
          // if (maxTemperature && selectedDevice) {
          //   items = await getDevicesByNameAndDateAndMaxTemperature(
          //     selectedDevice,
          //     formatDate(startDate),
          //     formatDate(endDate),
          //     maxTemperature
          //   );
          // } else if (selectedDevice) {
          //   items = await getDevicesByNameAndDate(
          //     selectedDevice,
          //     startDate,
          //     endDate
          //   );
          // }

          if (selectedDevice) {
            if (maxTemperature){
              items = await getDevicesByWatchListLoggerAndDateAndMaxTemperature(
                selectedDevice.WatchListLogger[0],
                selectedDevice.DatetimeInitial,
                selectedDevice.DatetimeFinal,
                maxTemperature
              );
            }
              
            else {
              items = await getDevicesByWatchListLoggerAndDate(
                selectedDevice.WatchListLogger[0],
                selectedDevice.DatetimeInitial,
                selectedDevice.DatetimeFinal
              );
              quality = await getQualityByWatchListLoggerAndDate(
                selectedDevice.WatchListLogger[0],
                selectedDevice.DatetimeInitial,
                selectedDevice.DatetimeFinal
              );

            }
            const sum = items.reduce(
              (acc, obj) => acc + obj["temperatureValueC"],
              0
            );
            const average = sum / items.length;
            setAverageTemperature(average);
            // console.log(quality)
            let quality_last = (quality[quality.length-1]["Flavor"] + 
              quality[quality.length-1]["Texture"] + 
              quality[quality.length-1]["Evolutionary"] + 
              quality[quality.length-1]["Oxidation"] + 
              quality[quality.length-1]["Appearance"] + 
              quality[quality.length-1]["Odor"])/6;
              quality_last = +(Math.round(quality_last + "e+3")  + "e-3")
             
            setQuality(quality_last);
          }
        }

        if (mounted) {
          let tempRow = items.filter(
            (item) => item.temperatureValueC < 1 || item.temperatureValueC > 5
          );
          setNumberOfAlerts(tempRow.length);
          setTempAlertData(tempRow);

          const uniqueDeviceIds = [
            ...new Set(items.map((item) => item.sentinelId)),
          ].sort();
         //  console.log(uniqueDeviceIds)
          //if(value==='1')
          setLines(
            uniqueDeviceIds.map((device) => ({
              name: "temperature",
              columnName: device,
              color: generateColorFromDeviceId(device),
            }))
          );
          setLinesU(
            uniqueDeviceIds.map((device) => ({
              name: "humidity",
              columnName: device,
              color: generateColorFromDeviceId(device),
            }))
          );
          console.log(uniqueDeviceIds);
          let temp=[]
          temp.push({name: "Odor", columnName: "Odor", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          temp.push({name: "Flavor", columnName: "Flavor", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          temp.push({name: "Texture", columnName: "Texture", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          temp.push({name: "Evolutionary", columnName: "Evolutionary", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          temp.push({name: "Oxidation", columnName: "Oxidation", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          temp.push({name: "Appearance", columnName: "Appearance", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          //temp.push({name: "temperature", columnName: "temperature", color: "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")});
          setBiLines(temp);
         // else
          setChartData(items);
          setChartDataQuality(quality);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setCombineError(error);
        handleError(error);
      }
    },
    [endDate, selectedDevice, maxTemperature, startDate]
  );

  useEffect(() => {
    console.log("fetch data");
    let mounted = true;

    fetchData(mounted);

    return () => (mounted = false);
  }, [fetchData]);

  const { error, isLoading } = useQuery(
    ["getData", startDate],
    () => getDevices(new Date(startDate)),
    {
      enabled: usequery,
      onSuccess: (data) => {
        const sum = data.reduce(
          (acc, obj) => acc + obj["temperatureValueC"],
          0
        );
        const average = sum / data.length;
        setAverageTemperature(average);
        let tempRow = data
          .filter((item) => item.temperatureValueC > 11)
          .sort((a, b) => {
            if (a.sentinelId < b.sentinelId) return -1;
            if (a.sentinelId > b.sentinelId) return 1;
            return 0;
          });
        setNumberOfAlerts(tempRow.length);
        setTempAlertData(tempRow);
      },
      onError: (error) => {
        handleError(error);
      },
    },
    [startDate]
  );

  useEffect(() => {
    //fetchData(true);
    //generateDateForDevices();
  }, []);

  // const { error: errorDevicesByDate, isLoading: isLoadingDevicesByDate } =
  //   useQuery(
  //     ["devicesByDate", startDate, endDate],
  //     () => getDevicesByDate(startDate, endDate),
  //     {
  //       enabled: startDate > 0 && endDate > 0,
  //       onSuccess: (data) => {
  //         setChartData(data);
  //       },
  //       onError: (error) => {
  //         handleError(error);
  //       },
  //     }
  //   );

  // const { error: errorDevices, isLoading: isLoadingChartDevices } =
  //   useQuery(["numberOfDevices"], getDeviceList, {
  //     onSuccess: (data) => {
  //       console.log(data)
  //       setNumberOfDevices(data.length);
  //       let ret = data.map((d) => {
  //         return { sentinelId: d };
  //       });

  //       const sortedDevices = ret.sort((a, b) => {
  //         if (a.sentinelId < b.sentinelId) return -1;
  //         if (a.sentinelId > b.sentinelId) return 1;
  //         return 0;
  //       });
  //       sortedDevices.push({
  //         sentinelId: "All devices",
  //       });
  //       setDevices(sortedDevices);
  //     },
  //     onError: (error) => {
  //       handleError(error);
  //     },
  //   });

  const {
    error: errorDevicesTestCode,
    isLoading: isLoadingChartDevicesTestCode,
  } = useQuery(["numberOfDevicesTestCode"], getDeviceListTestCode, {
    onSuccess: (data) => {
      setNumberOfDevices(data.length);
      const sortedDevices = data.sort((a, b) => {
        if (a.TestCode < b.TestCode) return -1;
        if (a.TestCode > b.TestCode) return 1;
        return 0;
      });
      setDevices(sortedDevices);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const { error: errorLocations, isLoading: isLoadingLocations } = useQuery(
    ["locationNumber"],
    getLocationNumber,
    {
      onSuccess: (data) => {
        setNumberOfLocations(data[0]?.totalDifferentLocations);
      },
    },
    {
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const { error: errorProducts, isLoading: isLoadingProducts } = useQuery(
    ["numberOfProducts"],
    getProducts,
    {
      onSuccess: (data) => {
        setNumberOfProducts(data.length);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const generateDateForDevices = () => {
    const { from, to } = generatePastDateRange(30); //generateNext7DaysDateRange();
    setStartDate(from);
    setUseQuery(true);
    setEndDate(to);
  };

  // const generateDateForDevicesTestCode = () => {
  //   const { from, to } = generatePastDateRange(30); //generateNext7DaysDateRange();
  //   setStartDate(from);
  //   setUseQuery(true);
  //   setEndDate(to);
  // };

  const generateColorFromDeviceId = (deviceId) => {
    const storedColor = localStorage.getItem(`color_${deviceId}`);
    if (storedColor) {
      return storedColor;
    }

    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random(42) * 16)];
    }
    // Store the color in Local Storage
    localStorage.setItem(`color_${deviceId}`, color);

    return color;
  };

  if (
    //isLoading ||
    isLoadingChartDevicesTestCode ||
    //isLoadingChartDevices ||
    isLoadingLocations ||
    isLoadingProducts ||
    isLoadingLocations
    // isLoadingDevicesByDate
  ) {
    return <Spinner />;
  }
  /*
  if (
    error ||
    errorDevices ||
    errorProducts ||
    errorLocations ||
    errorDevicesByDate ||
    combineError
  ) {
    return <AlertMessage message="Failed to fetch data. Please try again." />;
  }
  */

  const transformData = (data) => {
    const transformedData = {};
    data.forEach((item) => {
      const { sentinelId, timeOfReport, temperatureValueC } = item;

      if (!transformedData[timeOfReport]) {
        transformedData[timeOfReport] = {};
      }

      transformedData[timeOfReport]["temperature"] = temperatureValueC;
     // console.log(transformedData)
    });
    return Object.entries(transformedData).map(
      ([timeOfReport, temperatureValueC]) => ({
        timeOfReport,
        ...temperatureValueC,
      })
    );
  };

  const handleChangeDevice = (event) => {
    console.log(event.target.value);
    setSelectedDevice(event.target.value);
  };
  const handleChangeTemperature = (event) => {
    setMaxTemperature(event.target.value);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setChartData(chartData);
    setChartDataQuality(chartDataQuality);
  };

  const transformDataHumidity = (data) => {
    const transformedData = {};
    data.forEach((item) => {
      const { sentinelId, timeOfReport, humidity } = item;
    
      if (!transformedData[timeOfReport]) {
        transformedData[timeOfReport] = {};
      }

      transformedData[timeOfReport]["humidity"] = humidity;
     // console.log(transformedData)
    });

    return Object.entries(transformedData).map(
      ([timeOfReport, humidity]) => ({
        timeOfReport,
        ...humidity,
      })
    );
  };
  const transformDataQuality= (data) => {
    const transformedData = {};
    data.forEach((item) => {
      const { sentinelId, date, Appearance, Evolutionary, Flavor, Odor, Oxidation, Texture, temperature } = item;

      if (!transformedData[date]) {
        transformedData[date] = {};
      }

      transformedData[date]["Appearance"] = Appearance; 
      transformedData[date]["Evolutionary"] = Evolutionary;
      transformedData[date]["Flavor"] = Flavor; 
      transformedData[date]["Odor"] = Odor;
      //transformedData[date]["Flavor"] = Flavor; 
      transformedData[date]["Oxidation"] = Oxidation;
      transformedData[date]["Texture"] = Texture; 
      transformedData[date]["temperature"] = temperature;

    });

    return Object.entries(transformedData).map(
      ([date, Appearance, Evolutionary, Flavor, Odor, Oxidation, Texture,temperature]) => ({
        date,
        ...Appearance,
        ...Evolutionary,
        ...Flavor,
        ...Odor,
        ...Oxidation,
        ...Texture,
        ...temperature
      })
    );
  };

  return (
    <Box m="20px">
      <Header title="DASHBOARD" />
      {errorMessage && <AlertMessage message={errorMessage} />}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} justifyContent="center">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <CardOne
                data={{
                  value: numberOfAlerts,
                  // sx: { height: "150px", width: "150px" },
                  title: "Notified alerts",
                  cardIcon: <NotificationsIcon style={{ fontSize: "50px" }} />,
                  color: "warning",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <CardOne
                data={{
                  value: numberOfDevices || 0,
                  title: "Devices",
                  cardIcon: <DevicesIcon style={{ fontSize: "50px" }} />,
                  color: "secondary",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <CardOne
                data={{
                  value: quality || 0,
                  //symbol: "Ln",
                  cardIcon: <InventoryIcon style={{ fontSize: "50px" }} />,
                  InventoryIcon,
                  title: "Quality",
                  color: quality > 3 ? "secondary" : "warning",
                }}
              />
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={2}>
              <CardOne
                data={{
                  value: numberOfLocations || 0,
                  title: "Location",
                  cardIcon: <LocationOnIcon style={{ fontSize: "50px" }} />,
                  color: "secondary",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <CardOne
                data={{
                  value: numberOfProducts || 0,
                  title: "Tracked Products",
                  cardIcon: <LocalMallIcon style={{ fontSize: "50px" }} />,
                  color: "secondary",
                }}
              />
            </Grid> */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <CardOne
                data={{
                  value: averageTemperature.toFixed(2) + " \u00B0C" || 0,
                  title: "Average Temperature",
                  cardIcon: (
                    <DeviceThermostatIcon style={{ fontSize: "50px" }} />
                  ),
                  color: "secondary",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          // justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{ overflowX: "auto", pt: 1 }}
          >
            {/* start */}
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
              sx={{ minHeight: "10vh" }}
            >
              <Grid item>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    id="input-lable-select-device"
                    style={{ fontSize: "1.3em", marginBottom: "1.5em" }}
                  >
                    Device
                  </InputLabel>
                  <Select
                    labelId="select-device-label"
                    id="select-key"
                    value={selectedDevice}
                    label="Key"
                    onChange={handleChangeDevice}
                  >
                    {devices.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option["TestCode"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                {/* TODO: finish the location selection */}
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  {/* <InputLabel id="input-lable-select-device">
                    Location
                  </InputLabel> */}
                  <TextField
                    type="number"
                    id="standard-basic"
                    label="Temperature below"
                    variant="standard"
                    onChange={handleChangeTemperature}
                    value={maxTemperature}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">°C</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* end */}
          </Grid>
         
            <Grid sx={{ width:'100%'}} >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Temperature over range" value="1" />
                    <Tab label="Humidity" value="2" />
                    <Tab label="Quality Predict" value="3" />
                  </TabList>
                </Box>
                <TabPanel sx={{ padding: "0!important" }} value="1">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ height: 500 }}
                  >
                    <LineChartOne
                      chartData={chartData}
                      caption="Temperature over range"
                      timestampKey="timeOfReport"
                      lines={lines}
                      transformData={transformData}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ height: 500 }}
                  >
                    <AlertTables data={tempAlert} />
                  </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ padding: "0!important" }} value="2">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ height: 500 }}
                  >
                    <LineChartOne
                      chartData={chartData}
                      caption="Humidity"
                      timestampKey="timeOfReport"
                      lines={linesU}
                      transformData={transformDataHumidity}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ height: 500 }}
                  >
                    <AlertTables data={tempAlert} />
                  </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ padding: "0!important" }} value="3">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ height: 500 }}
                  >
                    <BiLineChart
                      chartData={chartDataQuality}
                      caption="Quality"
                      timestampKey="date"
                      lines={bilines}
                      transformData={transformDataQuality}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ height: 500 }}
                  >
                    <AlertTables data={tempAlert} />
                  </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Grid>
          
          {/* </TabContext> */}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              exportAsCSV(tempAlert);
            }}
            variant="contained"
            color="success"
            startIcon={<DownloadIcon />}
            sx={{
              justifyContent: "right",
              marginLeft: "auto",
              marginTop: 1,
            }}
          >
            DOWNLOAD REPORT
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomizedTables
            data={tempAlert}
            tableName="Devices"
            columnNames={columns}
            lastNextDay="Last"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
