import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tableCellClasses,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.primary.light,
    backgroundColor: "#ccc",
    color: theme.palette.common.black,
    fontSize: 18,
    maxWidth: 100,
    minWidth: 50,
    // wordWrap: "break-word",
    whiteSpace: "nowrap",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    maxWidth: 100,
    minWidth: 50,
    // wordWrap: "break-word",
    whiteSpace: "nowrap",
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CaptionTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#243746", //theme.palette.primary.gra,
    color: theme.palette.common.white,
    fontSize: 18,
    maxWidth: 30,
    minWidth: 20,
    wordWrap: "break-word",
    border: 0,
  },
}));

const AdditionalTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ccc",
    color: theme.palette.common.white,
    fontSize: 25,
    padding: 0,
    border: 0,
    maxWidth: 10,
    minWidth: 10,
    wordWrap: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 0,
    border: 0,
    maxWidth: 10,
    minWidth: 10,
    wordWrap: "break-word",
  },
}));

export default function CustomizedTables({
  data,
  tableName,
  columnNames,
  lastNextDay,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedRange, setSelectedRange] = useState("all");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const filtering = useCallback(
    (query, selected) => {
      return data.filter((row) => {
        if (selected === "all" && !query) {
          return true; // Show all rows if no range or search term is selected
        }

        const currentDate = new Date();
        const rowDate = new Date(row.time_of_report); // Assuming each row has a 'date' property

        // Calculate the date range based on the selected option
        let dateRange = {
          lastnext15days: 15,
          lastnext30days: 30,
        };

        let difference = rowDate.getTime() - currentDate.getTime();
        // Compare the row date with the date range
        if (lastNextDay === "Last") {
          difference = currentDate.getTime() - rowDate.getTime();
        }
        const diffInDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
        let condiction = diffInDays > 0 && diffInDays <= dateRange[selected];
        const isInRange = selected === "all" || condiction;

        // Check if the search term matches any column values
        const isMatch = Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(query.toLowerCase())
        );

        if (!isMatch && query) {
          return false;
        }
        if (!isInRange) {
          return false;
        }
        return true;
      });
    },
    [data, lastNextDay]
  );

  useEffect(() => {
    let query = searchQuery;
    let select = selectedRange;
    setFilteredData(filtering(query, select));
  }, [selectedRange, searchQuery, setFilteredData, filtering]);

  // useEffect(() => {
  //   setFilteredData(data);
  // },[setFilteredData,data]);

  const tableData = filteredData ? filteredData : data;
  const rows = tableData?.slice(startIndex, endIndex);
  const columns = columnNames;

  const handleDelete = (id) => {
    console.log(`Deleting item with id: ${id}`);
  };

  const handleEdit = (id) => {
    console.log(`Editing item with ID: ${id}`);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedRange(value);
  };

  return (
    <TableContainer component={Paper} sx={{ height: "100%" }}>
      <Table sx={{ minWidth: "550px" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <CaptionTableCell colSpan={12} align="center">
              {tableName}
            </CaptionTableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={columns.length - 2} align="left">
              <TextField
                label="Search"
                value={searchQuery}
                onChange={handleSearch}
                variant="outlined"
                size="small"
                align="left"
              />
            </TableCell>
            <TableCell colSpan={columns.length} align="right">
              <Select
                id="rangeSelect"
                style={{ minWidth: "150px", width: "181px", height: "36.7px" }}
                value={selectedRange}
                onChange={handleSelectChange}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"lastnext15days"}>
                  {lastNextDay} 15 days
                </MenuItem>
                <MenuItem value={"lastnext30days"}>
                  {lastNextDay} 30 days
                </MenuItem>
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="right" key={column.id}>
                {column.headerName}
              </StyledTableCell>
            ))}
            <AdditionalTableCell align="right">&nbsp;</AdditionalTableCell>
            <AdditionalTableCell align="right">&nbsp;</AdditionalTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) => (
              <StyledTableRow key={row._id}>
                {columns.map((column) => (
                  <TableCell
                    align="right"
                    key={column.id}
                    style={{ fontSize: 16 }}
                  >
                    {row[column.name]}
                  </TableCell>
                ))}
                <AdditionalTableCell align="right">
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="Edit"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </AdditionalTableCell>
                <AdditionalTableCell align="right">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="Delete"
                    style={{ padding: 0 }}
                  >
                    <DeleteIcon style={{ color: "red", padding: 0 }} />
                  </IconButton>
                </AdditionalTableCell>
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[2, 10, 50]}
        component="div"
        count={tableData?.length}
        rowsPerPage={rowsPerPage}
        page={!tableData?.length || tableData?.length <= 0 ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
