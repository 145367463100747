import axios from "./axiosConfig";

import { APP_ENDPOINT_PROD } from "../utils/Constant";
import { formatDate } from "../utils/dateUtils";

export const getDeviceList = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/deviceList`);
    return res.data?.[0]?.distinct_device_ids;
    
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getDevicesByName = async (device_id) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/S1.3/devicesById?device_id=${device_id}`
    );
    
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data.");
  }
};

export const getQualityByWatchListLoggerAndDate = async (
  device_id,
  start_date,
  end_date
) => {
  const params = {
    device_id: device_id,
    time_of_report_gte: formatDate(start_date),
    time_of_report_lte: formatDate(end_date)
  };

  try {
    const response = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/getQualityByWatchListLoggerAndDate`, {
      params: params
    });

    return response.data;
  } catch (error) {
    console.log(error);
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getQualityByDevicesByNameAndDate = async (
  device_id,
  start_date,
  end_date
) => {
  const params = {
    device_id: device_id,
    time_of_report_gte: formatDate(start_date),
    time_of_report_lte: formatDate(end_date)
  };

  try {
    const response = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/getQualityByDevicesByNameAndDate`, {
      params: params
    });

    return response.data;
  } catch (error) {
    console.log(error);
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getDevicesByNameAndDate = async (
  device_id,
  start_date,
  end_date
) => {
  const params = {
    device_id: device_id,
    time_of_report_gte: formatDate(start_date),
    time_of_report_lte: formatDate(end_date)
  };

  try {
    const response = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/devicesByNameAndDate`, {
      params: params
    });

    return response.data;
  } catch (error) {
    console.log(error);
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};


export const getDevices = async (endDate) => {
  console.log(isNaN(new Date(endDate)));
  try {
    const params = {
      time_of_report_gte: formatDate(endDate),
    };
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/devices`, {
      params: params
    });
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data.");
  }
};

export const getLocationNumber = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/locationNumber`);
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data.");
  }
};

export const getDevicesWithLocation = async (startDate) => {
  
  try {
    const params = {
      time_of_report_gte: formatDate(startDate),
    };
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/devicesWithLocation`, {
      params: params
    });
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data.");
  }
};

export const getDevicesByDate = async (startDate, endDate) => {
  const params = {
    time_of_report_gte: formatDate(startDate),
    time_of_report_lte: formatDate(endDate)
  };
  try {
    const response = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/devicesByDate`, {
      params: params
    });
    return response.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};


export const getDevicesByDateAndMaxTemperature = async (
  startDate,
  endDate,
  maxTemperature
) => {
  try {
    const params = {
      time_of_report_gte: formatDate(startDate),
      time_of_report_lte: formatDate(endDate),
      temperature_C_lte: maxTemperature
    };
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/devicesByDateAndMaxTemperature`, { params: params });
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getDevicesByNameAndDateAndMaxTemperature = async (
  device_id,
  start_date,
  end_date,
  maxTemperature
) => {
  const params = {
    device_id: device_id,
    time_of_report_gte: formatDate(start_date),
    time_of_report_lte: formatDate(end_date),
    temperature_C_lte: maxTemperature
  };

  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/devicesByNameAndDateAndMaxTemperature`, {
      params: params
    });

    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getDeviceListTestCode = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/deviceListTestCode`);
    return res.data;
    
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getDevicesByWatchListLoggerAndDate = async (
  device_id,
  start_date,
  end_date
) => {
  const params = {
    device_id: device_id,
    time_of_report_gte: formatDate(start_date),
    time_of_report_lte: formatDate(end_date)
  };

  try {
    const response = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/getDevicesByWatchListLoggerAndDate`, {
      params: params
    });

    return response.data;
  } catch (error) {
    console.log(error);
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};

export const getDevicesByWatchListLoggerAndDateAndMaxTemperature = async (
  device_id,
  start_date,
  end_date,
  maxTemperature
) => {
  const params = {
    device_id: device_id,
    time_of_report_gte: formatDate(start_date),
    time_of_report_lte: formatDate(end_date),
    temperature_C_lte: maxTemperature
  };

  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/getDevicesByWatchListLoggerAndDateAndMaxTemperature`, {
      params: params
    });

    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};