import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Dropdown = ({ selectedCategory, onSelect, data, title, isDisabled }) => {
  const handleChangeCategory = (event) => {
    onSelect(event.target.value);
  };
  return (
    <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
      <InputLabel id="input-lable-select-key">{title}</InputLabel>
      <Select
        labelId="select-key-label"
        id="select-key"
        value={selectedCategory || ""}
        label="Key"
        onChange={handleChangeCategory}
        disabled={isDisabled ? isDisabled : null}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item["sentinelId"]}>
            {item["sentinelId"]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
