import axios from "./axiosConfig";

import { APP_ENDPOINT_PROD } from "../utils/Constant";

export const getProducts = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/S1.3/products`);
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch product data");
  }
};
