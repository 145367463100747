// import IconPercentage from '../../icons/IconPercentage';
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  Icon,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const CardOne = (props) => {
  const { value, title, cardIcon, color } = props.data;

  return (
    <Card>
      <CardContent style={{padding:"8px"}}>
        <Stack direction="column" alignContent="center" alignItems="center" >
          <Avatar
            sx={{
              backgroundColor: "white",
            }}
            style={{ height: "70px", width: "70px" }}
          >
            <Icon color={color} style={{ height: "70px", width: "70px" }}>
              {cardIcon }
            </Icon>
          </Avatar>
          <Stack>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Typography color="text.secondary" variant="h5">
            {title}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
