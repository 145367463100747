import { Card, CardContent, Stack, Typography } from "@mui/material";

export const CardDetail = (props) => {
  const { value, sx, title, symbol } = props.data;

  return (
    <Card sx={sx}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: "0px",
        }}
        //alignItems="center"
      >
        <Stack backgroundColor="#5EC77B" width={sx.width}>
          <Typography
            color="#fff"
            height="50"
            align="center"
            variant="h5"
            padding="5px"
          >
            {title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h4" paddingTop="1em" fontWeight="bold">
            {value}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h6" padding="1em" fontWeight="bold">
            {symbol}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
