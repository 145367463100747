import axios from "axios";

import { APP_ENDPOINT, APP_ENDPOINT_PROD } from "../utils/Constant";

export const loginUser = async (credentials) => {
  const { email, password } = credentials;
  try {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const { data } = await axios.post(
      `${APP_ENDPOINT_PROD}/auth/login`,
      {
        client_id: "cold-chain-frontend",
        username: email,
        password,
      },
      config
    );
    return data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};

export const getUsers = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT}/login`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Authentication failed. Try again");
  }
};
