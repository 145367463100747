import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { Box, useTheme, IconButton, Tooltip, Typography } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

import { TbMap2 } from "react-icons/tb";

import useAuth from "../hooks/useAuth";
import useSidebar from "../hooks/useSidebar";

import logoImage from "../assets/logo_simple.png";

const Topbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const { isVisible, setVisible } = useState("true");
  const { openSidebar } = useSidebar();
  const { collapseSidebar, toggleSidebar, collapsed, broken, open } =
    useProSidebar();

  const theme = useTheme();
  const colors = theme.palette;
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate("/login");
    logout();
  };
  const handleAdminClick = () => {
    openSidebar();
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      bgcolor={colors.primary.main}
    >
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box>
          <img src={logoImage} alt="foodrus logo" height={30} />
        </Box>
        <Box marginTop={1}>
          <Typography color="white" fontSize={15} paddingLeft={2}>
            COLD CHAIN
          </Typography>
        </Box>
      </Box>
      {/* icons */}
      {isAuthenticated && (
        <Box display="flex">
          <Tooltip
            placement="bottom"
            title={<Typography fontSize={14}>Dashboard</Typography>}
          >
            <IconButton
              component={Link}
              to="/dashboard"
              style={
                location.pathname === "/dashboard"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <DashboardOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={14}>Monitoring</Typography>}
            placement="bottom"
            sx={{ fontSize: 30 }}
          >
            <IconButton
              component={Link}
              to="/monitoring"
              style={
                location.pathname === "/monitoring"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <MonitorHeartOutlinedIcon />
            </IconButton>
          </Tooltip>
          {/* <Tooltip
            title={<Typography fontSize={14}>Device Location</Typography>}
            placement="bottom"
          >
            <IconButton
              component={Link}
              to="/devicelocation"
              style={
                location.pathname === "/devicelocation"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <TbMap2 />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip
            title={<Typography fontSize={14}>History</Typography>}
            placement="bottom"
          >
            <IconButton
              component={Link}
              to="/history"
              style={
                location.pathname === "/history"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <HistoryOutlinedIcon />
            </IconButton>
          </Tooltip> */}
          {isVisible && (
            <Tooltip
              title={<Typography fontSize={14}>Admin</Typography>}
              placement="bottom"
            >
              <IconButton onClick={handleAdminClick}>
                <AdminPanelSettingsOutlinedIcon
                  style={{ color: colors.lightGrey.main }}
                />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            title={<Typography fontSize={14}>Logout</Typography>}
            placement="bottom"
          >
            <IconButton
              onClick={handleLogOut}
              style={{ color: colors.lightGrey.main }}
            >
              <ExitToAppOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Topbar;
